import React, { useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useDispatch } from 'react-redux';
import NotificationsActions from '~redux/notifications';
import PromptService from '~services/prompt';
import { submitUnsubscribe } from './utils';
import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { AuthContext } from '~contexts/auth';

import Hero from '~components/hero';

import { Checkbox } from '~components/forms/checkbox';
import Button from '~components/button';

import './style.scss';

import { getValidatedQueryString } from '~helpers/queryUtils';


const Unsubscribe = () => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, signOut } = useContext(AuthContext);

  useLayout({});
  useSeo(
    intl.formatMessage({
      id: 'containers.unsubscribe.seo.title',
    })
  );

  const code = queryString.parse(getValidatedQueryString(location.search)).code;

  const [newUsersAlerts, setNewUsersAlerts] = useState(true);
  const [personalMessages, setPersonalMessages] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleSave = async () => {
    if(!personalMessages) {
      history.push(`/unsubscribe-actions?code=${code}&newUsersAlerts=${newUsersAlerts}&personalMessages=${personalMessages}`);
      return;
    }

    const actions = {
      published: true,
      is_deleted: false,
      user_alert: newUsersAlerts,
      personal_message: personalMessages,
    };

    try {
      setIsSaving(true);
      await submitUnsubscribe({ actions, code });
      dispatch(
        NotificationsActions.addNotification(
          intl.formatMessage({
            id: `containers.unsubscribe.notification.success`,
            defaultMessage: 'Unsubscribed successfully',
          })
        )
      );
      setIsSaved(true);
      if(isAuthenticated && personalMessages && accountAction === 'close-account') {
        signOut();
      }
    } catch (err) {
      console.log('Error while archiving account');
      PromptService.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <section className="c-page l-container">
        <Hero.Wrapper>
          <Hero.Title>
            {intl.formatMessage({
              id: 'containers.unsubscribe.headline',
            })}
          </Hero.Title>
          {isSaved ? (
            <Hero.Intro>
              {intl.formatMessage({
                id: 'containers.unsubscribe.success-message',
              })}
            </Hero.Intro>
          ) : (
            <Hero.Intro>
              {intl.formatMessage({
                id: 'containers.unsubscribe.introduction',
              })}
            </Hero.Intro>
          )}
        </Hero.Wrapper>

        <div className="c-unsubscribe l-page-content">
          {!code ? (
            <div>Invalid code</div>
          ) : !isSaved ? (
              <div className="c-unsubscribe-checkboxes">
                <Checkbox
                  checked={newUsersAlerts}
                  onChange={() => setNewUsersAlerts((prev) => !prev)}
                  className="l-bordered c-checkbox--with-children"
                >
                  <div className="c-checkbox__children">
                    <div className="c-checkbox__title">
                      {intl.formatMessage({
                        id: 'containers.unsubscribe.new-users-alert.title',
                      })}
                    </div>
                    <div className="c-checkbox__content">
                      {intl.formatMessage({
                        id: 'containers.unsubscribe.new-users-alert.content',
                      })}
                    </div>
                  </div>
                </Checkbox>
                <Checkbox
                  checked={personalMessages}
                  onChange={() => setPersonalMessages((prev) => !prev)}
                  className="l-bordered c-checkbox--with-children"
                >
                  <div className="c-checkbox__children">
                    <div className="c-checkbox__title">
                      {intl.formatMessage({
                        id: 'containers.unsubscribe.personal-messages.title',
                      })}
                    </div>
                    <div className="c-checkbox__content">
                      {intl.formatMessage({
                        id: 'containers.unsubscribe.personal-messages.content',
                      })}
                    </div>
                  </div>
                </Checkbox>
              </div>
          ) : null}

          {!isSaved && (
            <div className="l-center l-mt2">
              <Button onClick={handleSave} isLoading={isSaving}>
                {intl.formatMessage({
                  id: 'containers.unsubscribe.save',
                })}
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Unsubscribe;
