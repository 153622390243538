import * as REST from '~services/rest';

export const submitUnsubscribe = async ({ actions, code }) => {
    if (!actions || !code) {
      throw new Error;
    }
  
    await REST.post({
      name: `unsubscribe?code=${code}`,
      params: {
        ...actions,
      },
      shouldThrowError: true,
    });
  };